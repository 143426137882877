import ExternalLink from 'components/links/ExternalLink';
import SecondaryLink from 'components/links/SecondaryLink';
import UndecoratedLink from 'components/links/UndecoratedLink';
import BookOpenSVG from 'images/icons/book-open.inline.svg';
import React from 'react';
import 'styles/faq.css';

export interface IFAQSection {
    name: string;
    entries: IFAQSectionEntry[];
}

export interface IFAQSectionEntry {
    frontmatter: {
        title: string;
        parent: string;
    };
    fields: {
        slug: string;
        sourceInstanceName: string;
    };
    id: string;
}

/**
 * The section names, in the order in which we want to display them on the FAQ
 * index page.
 */
export const sectionNamesIndexPage = [
    'Features',
    'Security and privacy',
    'Subscription',
    'Migration',
    'General',
];

/**
 * The section names, in the order in which we want to display them on the FAQ
 * entry pages.
 */
export const sectionNamesEntryPages = [
    'Features',
    'Security and privacy',
    'Migration',
    'Subscription',
    'General',
];

/**
 * Convert the list of grouped FAQ  entries received in an GraphQL response for
 * all FAQ entries into the "IFAQSectionEntry" type that is used by other
 * components dealing with FAQ entries.
 *
 * `sectionNames` specifies the order in which the resultant sections should be.
 */
export const makeSections = (
    groups: { nodes: IFAQSectionEntry[] }[],
    sectionNames: string[]
) => {
    return sectionNames.map((name) => {
        const group = groups.find((group) => {
            return group.nodes[0].frontmatter.parent === name;
        });
        const entries = group?.nodes ?? [];
        return { name, entries };
    });
};

/** The FAQ heading to use on the index page */
export const FAQHeading: React.FC = () => {
    return (
        <div className="text-center my-h">
            <BookOpenSVG className="text-muted opacity-25" />
            <h1 className="text-title">Frequently asked questions</h1>
        </div>
    );
};

/**
 * The FAQ heading to use on the rest of the FAQ pages
 *
 * It also serves a link back to the index page.
 */
export const FAQIndexLinkHeading: React.FC = () => {
    return (
        <SecondaryLink to="/faq">
            <div className="text-center my-h">
                <BookOpenSVG className="opacity-25" />
                <h1>Frequently asked questions</h1>
            </div>
        </SecondaryLink>
    );
};

interface FAQSectionProps {
    section: IFAQSection;
    className?: string;
    highlightedEntryID?: string;
}

const FAQSection: React.FC<FAQSectionProps> = ({
    section,
    className,
    highlightedEntryID,
}) => {
    const { name, entries } = section;

    const bg = faqBackgroundClass(section);
    const extraClasses = [className, bg].filter((x) => x).join(' ');

    return (
        <section
            className={`bg-elevated rounded-3 px-4 pb-3 text-muted ${extraClasses}`}>
            <h3>{name}</h3>
            <ul>
                {entries.map((entry) => (
                    <FAQLink
                        key={entry.id}
                        entry={entry}
                        shouldHighlight={entry.id === highlightedEntryID}
                    />
                ))}
            </ul>
        </section>
    );
};

export default FAQSection;

const faqBackgroundClass = ({ name }: IFAQSection) => {
    const id = name.toLowerCase().replace(/\s/g, '-');
    return `faq-section-bg faq-section-bg-${id}`;
};

interface FAQLinkProps {
    entry: IFAQSectionEntry;
    /**
     * If true, the link will be highlighted (e.g. to mark the current
     * question in the list of questions). */
    shouldHighlight?: boolean;
}

const FAQLink: React.FC<FAQLinkProps> = ({ entry, shouldHighlight }) => {
    const {
        frontmatter: { title },
        fields: { slug, sourceInstanceName },
        id,
    } = entry;

    const path = `/${sourceInstanceName}${slug}`;

    return (
        <li key={id} className="my-2">
            {shouldHighlight ? (
                <b>
                    <UndecoratedLink to={path}>{title}</UndecoratedLink>
                </b>
            ) : (
                <UndecoratedLink to={path}>{title}</UndecoratedLink>
            )}
        </li>
    );
};

export const FAQUnclearSection: React.FC = () => {
    return (
        <section className="text-center mt-5">
            Something unclear? Please reach out to us at{' '}
            <ExternalLink href="mailto:support@ente.io">
                support@ente.io
            </ExternalLink>
        </section>
    );
};
