import { Link } from 'gatsby';
import styled from 'styled-components';
import ExternalLink from './ExternalLink';

const SecondaryLink = styled(Link).attrs({
    className: 'link-secondary',
})``;

export default SecondaryLink;

export const SecondaryExternalLink = styled(ExternalLink).attrs({
    className: 'link-secondary',
})``;
