import { Link } from 'gatsby';
import styled from 'styled-components';
import ExternalLink from './ExternalLink';

/** Variant of primary Links without the underline */
const UndecoratedLink = styled(Link).attrs({
    className: 'link-undecorated',
})``;

export default UndecoratedLink;

export const UndecoratedExternalLink = styled(ExternalLink).attrs({
    className: 'link-undecorated',
})``;
