import BasicSection from 'components/BasicSection';
import FAQSection, {
    FAQIndexLinkHeading,
    FAQUnclearSection,
    IFAQSection,
    IFAQSectionEntry,
    makeSections,
    sectionNamesEntryPages,
} from 'components/FAQSection';
import Layout from 'components/Layout';
import { graphql, PageProps } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';

import 'styles/faq.css';

interface DataProps {
    mdx: IFAQEntry;
    allMdx: {
        group: {
            nodes: IFAQSectionEntry[];
        }[];
    };
}

interface IFAQEntry {
    frontmatter: {
        title: string;
        parent: string;
    };
    body: string;
    id: string;
}

const FAQ: React.FC<PageProps<DataProps>> = ({ path, data }) => {
    const { mdx, allMdx } = data;

    // Create aliases using names that we use elsewhere.
    const entry = mdx;
    const groups = allMdx.group;

    const sections = makeSections(groups, sectionNamesEntryPages);
    const { relatedSection, otherSections } = splitRelatedSection(
        entry,
        sections
    );

    const title = `FAQ - ${entry.frontmatter.title}`;

    return (
        <Layout title={title} path={path}>
            <BasicSection>
                <BasicSection.Header>
                    <FAQIndexLinkHeading />
                </BasicSection.Header>

                <BasicSection.Body className="max-w-xl">
                    <MainContent {...{ entry, relatedSection }} />
                    <FooterContent {...{ otherSections }} />
                </BasicSection.Body>
            </BasicSection>
        </Layout>
    );
};

export default FAQ;

export const query = graphql`
    query ($id: String!) {
        mdx(id: { eq: $id }) {
            frontmatter {
                title
                parent
            }
            body
            id
        }
        allMdx(
            filter: { fields: { sourceInstanceName: { eq: "faq" } } }
            sort: { fields: frontmatter___index, order: ASC }
        ) {
            group(field: frontmatter___parent) {
                nodes {
                    frontmatter {
                        title
                        parent
                    }
                    fields {
                        slug
                        sourceInstanceName
                    }
                    id
                }
            }
        }
    }
`;

const splitRelatedSection = (entry: IFAQEntry, sections: IFAQSection[]) => {
    const split = sections.reduce(
        (split, section) => {
            split[section.name === entry.frontmatter.parent ? 0 : 1].push(
                section
            );
            return split;
        },
        [[] as IFAQSection[], [] as IFAQSection[]]
    );
    // We want the type of the relatedSection to be an optional T. Direct
    // indexing (`split[0]`) returns a non-optional.
    //
    // Use .find(Boolean) as a substitute for .first (not present in JS).
    const relatedSection = split[0].find(Boolean);
    return { relatedSection, otherSections: split[1] };
};

interface MainContentProps {
    entry: IFAQEntry;
    relatedSection?: IFAQSection;
}

const MainContent: React.FC<MainContentProps> = ({ entry, relatedSection }) => {
    return (
        <div className="faq-content-container align-items-start">
            <FAQEntry {...{ entry }} />
            {relatedSection && (
                <RelatedSection
                    section={relatedSection}
                    highlightedEntryID={entry.id}
                />
            )}
        </div>
    );
};

const FAQEntry: React.FC<{ entry: IFAQEntry }> = ({ entry }) => {
    const { frontmatter, body } = entry;
    const { title } = frontmatter;

    return (
        <article className="faq-content-entry">
            <h2 className="px-4">{title}</h2>
            <div className="bg-elevated rounded-3 px-4 py-3">
                <MDXRenderer>{body}</MDXRenderer>
            </div>
        </article>
    );
};

interface RelatedSectionProps {
    section: IFAQSection;
    highlightedEntryID: string;
}

const RelatedSection: React.FC<RelatedSectionProps> = (props) => {
    return (
        <div className="faq-content-related-section d-flex flex-column">
            <h3 className="text-center text-muted">Related topic</h3>
            <FAQSection {...props} />
        </div>
    );
};

const FooterContent: React.FC<{ otherSections: IFAQSection[] }> = ({
    otherSections,
}) => {
    return (
        <div className="max-w-lg m-auto my-5">
            <div className="d-flex flex-column gap-4">
                <h3 className="text-center">Other topics</h3>
                {otherSections.map((section) => (
                    <FAQSection
                        key={section.name}
                        section={section}
                        className="px-lg-4"
                    />
                ))}
            </div>
            <FAQUnclearSection />
        </div>
    );
};
